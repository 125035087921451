import routerOptions0 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_rhoqatvm5ofndrecimabewegzy/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/apps/nuxt-front/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}